<template>
  <div class="float-manage">
    <div class="float-show" @click="$router.push('/shopcart')">
      <i class="el-icon-shopping-cart-1" style="font-size: 28px"></i>
      <span>购物车</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "FloatManage",
  data() {
    return {};
  },

  components: {},

  computed: {},
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.float-manage {
  position: fixed;
  bottom: 25%;
  right: 0;
  width: 80px;
  height: 60px;
  background-color: rgba($color: #fff, $alpha: 1);
  z-index: 999;
  border-radius: 5px;

  .float-show {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-size: 12px;
    border: 1px solid #eee;
    cursor: pointer;

    &:hover {
      color: red;
      img {
        content: url("../assets/images/float_icon_active.png");
      }
    }
  }
}
</style>
