import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cloudPc: localStorage.getItem("cloud_pc")
      ? localStorage.getItem("cloud_pc")
      : {},
    loginMethods: () => {},
    registerMethod: () => {},
    forgetPwdMethod: () => {},
  },
  mutations: {
    setCloudPc(state, payload) {
      state.business = payload;
    },
    setEmployeeInfo(state, payload) {
      state.empInfo = payload;
    },
    getGlobalLogin(state, payload) {
      state.loginMethods = payload;
    },
    getGlobalRegister(state, payload) {
      state.registerMethod = payload;
    },
    getGlobalForgetPwd(state, payload) {
      state.forgetPwdMethod = payload;
    },
    removeCloudPc(state, payload) {
      state.cloudPc = {};
    },
  },
  actions: {
    SET_CLOUD_PC(context, payload) {
      localStorage.setItem("cloud_pc", JSON.stringify(payload));
      context.commit("setCloudPc", payload);
    },
    REMOVE_CLOUD_PC(context, payload) {
      localStorage.removeItem("cloud_pc");
      context.commit("removeCloudPc");
    },
    GET_GLOBAL_LOGIN(context, payload) {
      context.commit("getGlobalLogin", payload);
    },
    GET_GLOBAL_REGISTER(context, payload) {
      context.commit("getGlobalRegister", payload);
    },
    GET_GLOBAL_FORGET(context, payload) {
      context.commit("getGlobalForgetPwd", payload);
    },
  },
  modules: {},
});
