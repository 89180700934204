<template>
  <div class="footer-wrapper">
    <footer>
      <ul class="footer-link">
        <li class="link">
          <b>友情链接：</b>
          <div>
            <a target="_blank" href="https://www.saac.gov.cn/">国家档案局</a>
            <a target="_blank" href="http://www.idangan.cn/">中国档案学会</a>
            <a target="_blank" href="http://www.saacedu.org.cn/">
              国家档案局档案干部教育中心
            </a>
            <a target="_blank" href="https://fhac.com.cn">中国第一历史档案馆</a>
            <a target="_blank" href="http://www.shac.net.cn/"
              >中国第二历史档案馆</a
            >
            <a target="_blank" href="http://irm.ruc.edu.cn/">
              中国人民大学信息资源管理学院
            </a>
            <a target="_blank" href="https://www.cas.buu.edu.cn/">
              北京联合大学应用文理学院
            </a>
          </div>
        </li>
        <li>
          <b>客服电话：</b>
          <span>159-0127-4612</span>
        </li>
        <li>
          <b>合作洽谈：</b>
          <span>宋老师：189-3185-6239</span>
        </li>
        <li>
          <b>公司地址：</b>
          <span>北京市昌平区北七家镇宏福科技园10号院5号楼</span>
        </li>
      </ul>
      <div class="right">
        <div class="top">
          <div>
            <img src="../assets/images/xlzs.png" alt="" />
            <span>微信客服号</span>
          </div>
          <div>
            <img src="../assets/images/gzh.png" alt="" />
            <span>公司公众号</span>
          </div>
        </div>
        <p>扫一扫，关注我们，了解更多详情</p>
      </div>
    </footer>
    <div class="tech-support">
      Copyright 2019-2020 北京小兰老师科技有限公司京1CP备20030625号-1
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  components: {},

  computed: {},

  methods: {},
};
</script>
<style lang="scss" scoped>
.footer-wrapper {
  width: 100%;
  position: absolute;
  bottom: -350px;
}
footer {
  height: 300px;
  background-color: #f2f2f2;
  position: relative;
  .footer-link {
    width: 50%;
    position: absolute;
    left: 10%;
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      margin-top: 30px;
    }
    a,
    span {
      text-decoration: none;
      color: #999;
      margin-right: 10px;
    }
    .link {
      display: flex;
      b {
        min-width: 80px;
      }
    }
  }
  & > div.right {
    height: 100%;
    position: absolute;
    right: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .top {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #999;
      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // margin-right: 50px;
        margin: 0 25px;
      }
      img {
        margin-bottom: 10px;
      }
    }
    p {
      font-size: 18px;
      color: #999;
    }
  }
}
.tech-support {
  width: 100%;
  padding: 20px;
  text-align: center;
  font-size: 14px;
  color: #333;
  background: #e5e5e5;
  box-sizing: border-box;
  cursor: pointer;
  span {
    cursor: default;
  }
}
</style>
