import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "Index",
    meta: { title: "首页" },
    component: () => import("../views/Index.vue"),
  },
  {
    path: "/personal",
    name: "Personal",
    meta: { title: "个人中心", isLogin: true },
    component: () => import("../views/Personal.vue"),
  },
  {
    path: "/search",
    name: "Search",
    component: () => import("../views/Search.vue"),
  },
  {
    path: "/standard",
    name: "Standard",
    component: () => import("../views/Standard.vue"),
  },
  {
    path: "/exercise",
    name: "ExamExercises",
    meta: { title: "练习与考试" },
    component: () => import("../views/ExamExercises.vue"),
  },
  {
    path: "/exampage",
    name: "ExamPage",
    meta: { title: "考试页", isLogin: true },
    component: () => import("../views/ExamPage.vue"),
  },
  {
    path: "/results",
    name: "ExamResults",
    meta: { title: "考试记录", isLogin: true },
    component: () => import("../views/ExamResults.vue"),
  },
  {
    path: "/courlist",
    name: "CourseList",
    meta: { title: "课程列表" },
    component: () => import("../views/CourseList.vue"),
  },
  {
    path: "/courdetails",
    name: "CourseDetails",
    meta: { title: "课程详情", isLogin: true },
    component: () => import("../views/CourseDetails.vue"),
  },
  {
    path: "/courplay",
    name: "CoursePlay",
    meta: { title: "课程播放", isLogin: true },
    component: () => import("../views/CoursePlay.vue"),
  },
  {
    path: "/courtype",
    name: "CourseType",
    meta: { title: "课程分类" },
    component: () => import("../views/CourseType.vue"),
  },
  {
    path: "/shopcart",
    name: "ShopCart",
    meta: { title: "购物车", isLogin: true },
    component: () => import("../views/ShopCart.vue"),
  },
  {
    path: "/order",
    name: "ConfrimOrder",
    meta: { title: "确认订单", isLogin: true },
    component: () => import("../views/ConfrimOrder.vue"),
  },
];

const router = new VueRouter({
  routes,
});

//路由守卫
// router.beforeEach((to, from, next) => {
//   let token = localStorage.getItem("eft");
//   console.log(token);

//   if (token === null || token === "") {
//     next("/");
//   } else {
//     next();
//   }
// });

export default router;
