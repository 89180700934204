<template>
  <div id="app">
    <Header />
    <router-view />
    <FloatManage />
    <Footer />
  </div>
</template>
<script>
import Header from "./components/Header.vue";
import FloatManage from "./components/FloatManage.vue";
import Footer from "./components/Footer.vue";
export default {
  data() {
    return {};
  },
  components: {
    Header,
    FloatManage,
    Footer,
  },
  updated() {
    this.scrollTop();
  },
  methods: {
    scrollTop() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
  },
};
</script>
<style lang="scss" scoped></style>
<style lang="scss">
#app {
  min-height: 100vh;
  position: relative;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.bread-crumb {
  max-width: 1200px;
  margin: 20px auto 30px;
  background-color: transparent;
  overflow: hidden;
  .el-breadcrumb__item {
    font-size: 18px;
    cursor: pointer;
    span {
      cursor: pointer;
    }
  }
}
</style>
