<template>
  <div class="header">
    <div class="left">
      <div class="logo-wrapper">
        <div class="logo">
          <img src="../assets/images/logo.png" alt="logo" />
        </div>
      </div>
      <div class="center-menu">
        <span class="menu-index" @click="$router.push('/')">首页</span>
        <span class="menu-types">
          <b @click="dropDownShowFunc" ref="classtypestitle">课程分类</b>
          <transition
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut"
          >
            <div class="drop-down" v-show="dropDownShow" ref="classtypes">
              <div v-for="course in typeList" :key="course.id">
                <span @click="toCourseList(course)">
                  {{ course.title }}
                </span>
              </div>
            </div>
          </transition>
        </span>
      </div>
      <div class="search">
        <el-input
          class="search_in"
          v-model="searchVal"
          :placeholder="`请输入${search_menu == 1 ? '课程' : '标准规范'}名称`"
          @keyup.enter.native="globalSearch"
        >
        </el-input>
        <div class="search_but">
          <p class="search_but_text" @click="globalSearch">搜索</p>
        </div>
      </div>
      <div class="right" v-if="$route.path !== '/'">
        <span
          class="please"
          style="display: inline-block; display: flex"
          v-if="!userInfo || JSON.stringify(userInfo) == '{}'"
          @click="loginMask"
        >
          <img style="width: 40px; height: 40px" :src="defaulHeader" alt="" />
          <p style="line-height: 10px; margin-left: 7px">请登录</p>
        </span>

        <el-dropdown trigger="click" style="cursor: pointer" v-else>
          <span class="el-dropdown-link" style="display: flex">
            <img
              style="
                width: 40px;
                height: 40px;
                border-radius: 50%;
                margin-top: 3px;
              "
              :src="
                userInfo && userInfo.head_img !== null
                  ? `https://mobile.xlteacher.cn${userInfo.head_img}`
                  : defaulHeader
              "
              alt="avator"
            />
            <p class="header_name">
              {{ userInfo.name }}
              <i
                class="el-icon-arrow-down el-icon--right"
                style="margin-top: 3%; margin-left: 55%"
              ></i>
            </p>
          </span>
          <div class="mymenu">
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="updateInfo"
                >个人中心</el-dropdown-item
              >
              <el-dropdown-item @click.native="logOut"
                >退出登录</el-dropdown-item
              >
            </el-dropdown-menu>
          </div>
        </el-dropdown>
      </div>
    </div>
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
      <div class="mask" v-if="maskShow">
        <div class="login-mask" v-if="loginShow" key="login">
          <i class="el-icon-close" @click="closeLoginMask('loginForm')"></i>
          <span>登录</span>
          <el-form
            ref="loginForm"
            label-width="auto"
            :rules="loginRule"
            :model="loginForm"
          >
            <el-form-item prop="mobile">
              <p slot="label" style="margin: 0"></p>
              <el-input
                placeholder="请输入手机号"
                prefix-icon="el-icon-mobile-phone"
                v-model="loginForm.mobile"
                type="number"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="password" style="margin-bottom: 5px">
              <p slot="label" style="margin: 0"></p>
              <el-input
                type="password"
                placeholder="请输入密码"
                prefix-icon="el-icon-lock"
                maxlength="16"
                @input="
                  loginForm.password = loginForm.password.replace(
                    /[^\w\.\/]/gi,
                    ''
                  )
                "
                v-model="loginForm.password"
              >
              </el-input>
            </el-form-item>
            <el-form-item style="width: 90%; margin: 0 auto 20px">
              <div
                style="float: left; color: #409eff; cursor: pointer"
                @click="openMask"
              >
                立即注册
              </div>
              <div
                style="float: right; color: #909399; cursor: pointer"
                @click="openForget"
              >
                忘记密码
              </div>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                @click.prevent="loginMethods('loginForm')"
                >登录</el-button
              >
              <el-button @click="closeLoginMask('loginForm')">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="forget-mask" v-if="forgetShow" key="forget">
          <i class="el-icon-close" @click="closeForget('forgetForm')"></i>
          <span>忘记密码</span>
          <el-form
            :rules="forgetRule"
            :model="forgetForm"
            ref="forgetForm"
            label-width="auto"
          >
            <el-form-item prop="mobile">
              <p slot="label" style="margin: 0"></p>
              <el-input
                type="number"
                placeholder="请输入手机号"
                prefix-icon="el-icon-mobile-phone"
                v-model="forgetForm.mobile"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="code">
              <p slot="label" style="margin: 0"></p>
              <div class="msg-box">
                <el-input
                  type="number"
                  placeholder="请输入短信验证码"
                  prefix-icon="el-icon-lock"
                  v-model="forgetForm.code"
                >
                </el-input>
                <button
                  @click.prevent="sendForgetCode"
                  v-show="countDown === 60"
                >
                  获取验证码
                </button>
                <button v-show="countDown !== 60">
                  重新发送 ({{ countDown }})
                </button>
              </div>
            </el-form-item>
            <el-form-item prop="password">
              <p slot="label" style="margin: 0"></p>
              <el-input
                maxlength="16"
                type="password"
                placeholder="请输入新密码"
                prefix-icon="el-icon-lock"
                v-model="forgetForm.password"
                @input="
                  forgetForm.password = forgetForm.password.replace(
                    /[^\w\.\/]/gi,
                    ''
                  )
                "
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="real_password">
              <p slot="label" style="margin: 0"></p>
              <el-input
                maxlength="16"
                type="password"
                placeholder="请确认密码"
                prefix-icon="el-icon-lock"
                v-model="forgetForm.real_password"
                @input="
                  forgetForm.real_password = forgetForm.real_password.replace(
                    /[^\w\.\/]/gi,
                    ''
                  )
                "
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="forgetReq('forgetForm')"
                >确定</el-button
              >
              <el-button @click="closeForget('forgetForm')">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="register" v-if="registerShow" key="register">
          <i class="el-icon-close" @click="closeMask('registerForm')"></i>
          <span>注册</span>
          <form>
            <label class="label-phone-icon">
              <input
                type="number"
                placeholder="请输入手机号"
                @blur="checkMobile('mobile-check')"
                @input="
                  if (resgisterForm.mobile.length > 11)
                    resgisterForm.mobile = resgisterForm.mobile.slice(0, 11);
                "
                v-model="resgisterForm.mobile"
              />
              <b class="check-mark" ref="mobile-check">请输入手机号</b>
            </label>

            <label class="label-password-icon">
              <input
                type="password"
                placeholder="密码"
                maxlength="16"
                v-model="resgisterForm.password"
                @blur="checkPwd('password-check')"
                @input="
                  resgisterForm.password = resgisterForm.password.replace(
                    /[^\w\.\/]/gi,
                    ''
                  )
                "
              />
              <b class="check-mark" ref="password-check">请输入密码</b>
            </label>
            <label class="label-password-icon">
              <input
                type="password"
                maxlength="16"
                placeholder="确认密码"
                v-model="resgisterForm.real_password"
                @input="
                  resgisterForm.real_password =
                    resgisterForm.real_password.replace(/[^\w\.\/]/gi, '')
                "
                @blur="checkRePwd('re-password-check')"
              />
              <b class="check-mark" ref="re-password-check">请输入确认密码</b>
            </label>
            <label class="label-code-icon">
              <input
                type="number"
                placeholder="验证码"
                @blur="checkCaptcha('captcha-check')"
                v-model="resgisterForm.code"
              />
              <button
                class="send-code-btn"
                @click.prevent="sendCode"
                v-show="countDown === 60"
              >
                发送验证码
              </button>
              <button
                class="send-code-btn send-disable"
                disabled
                v-show="countDown !== 60"
              >
                重新发送( {{ countDown }} )
              </button>
              <b class="check-mark" ref="captcha-check">请输入验证码 </b>
            </label>
            <button class="now-register" @click.prevent="registerReq">
              立即注册
            </button>
          </form>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import "animate.css";
import defaulHeader from "../assets/images/default_header.png";
export default {
  data() {
    return {
      search_menu: "1",
      searchVal: "",
      myHead: {},
      myBusiness: {},
      personImgUrl: "",
      defaulHeader: defaulHeader, //默认显示头像路径
      typeName: "", //

      certInfo: {},
      dropDownShow: false,
      //
      maskShow: false,
      registerShow: false,
      loginShow: false,
      forgetShow: false,
      loginForm: {
        mobile: "",
        password: "",
      }, //登陆表单
      loginRule: {
        mobile: { required: true, message: "请输入手机号", trigger: "blur" },
        password: { required: true, message: "请输入密码", trigger: "blur" },
      }, //登陆表单验证
      resgisterForm: {
        mobile: "",
        code: "",
        password: "",
        real_password: "",
      },
      countDown: 60,
      forgetRule: {
        mobile: { required: true, message: "请输入手机号", trigger: "blur" },
        code: { required: true, message: "请输入验证码", trigger: "blur" },
        password: { required: true, message: "请输入密码", trigger: "blur" },
        real_password: {
          required: true,
          message: "请输入确认密码",
          trigger: "blur",
        },
      },
      forgetForm: {
        mobile: "",
        code: "",
        password: "",
        real_password: "",
      },
      typeList: [],
    };
  },
  mounted() {
    this.$store.dispatch("GET_GLOBAL_LOGIN", this.loginMask);
    this.$store.dispatch("GET_GLOBAL_REGISTER", this.openMask);
    this.$store.dispatch("GET_GLOBAL_FORGET", this.openForget);
    this.getCourseType();
    addEventListener("click", (e) => {
      if (this.$refs.classtypes) {
        const isSelf = this.$refs.classtypes.contains(e.target);
        const isFa = this.$refs.classtypestitle.contains(e.target);
        if (isFa) {
          return false;
        }
        if (!isSelf) {
          this.dropDownShow = false;
        }
      }
    });
  },
  methods: {
    toCourseList(course) {
      this.$router.push(`courlist?tid=${course.id}&tn=${course.title}`);
      this.dropDownShow = false;
    },
    dropDownShowFunc(e) {
      this.dropDownShow = !this.dropDownShow;
    },

    updateInfo() {
      this.$router.push("/personal");
    },
    logOut() {
      this.axios.post("/course/personal/login_out").then((res) => {
        this.$store.dispatch("REMOVE_CLOUD_PC");
        this.$router.push("/");
      });
    },
    chengeSearchFlag(currentVal) {
      this.search_menu = currentVal;
    },
    globalSearch() {
      if (this.searchVal === "") {
        this.$message.error("请输入搜索内容!");
        return;
      }
      if (this.search_menu == 1) {
        this.$router.push(`/search?search=${this.searchVal}&page=1`);
        if (this.$route.name === "Search") {
          this.$router.go(0);
        }
      }

      this.searchVal = "";
    },
    getCourseType() {
      this.axios.post("/coursepc/index/index").then((res) => {
        this.typeList = res.data.type_list;
      });
    },
    //  获取个人设置请求
    getPersonalSetData() {
      this.axios.post("index/my_settings").then((res) => {
        if (res.code == 0) {
          this.getPersonalSetData();
          this.getheadData();
        }
        console.log(res);
        this.realname = res.data.name;
        this.personImgUrl = res.data.head_img;
      });
    },

    getIndexData() {
      this.axios.get("/").then((res) => {
        console.log(1);
        if (
          res.data.user_info &&
          typeof this.$store.state.empInfo !== "Object"
        ) {
          this.$store.dispatch(
            "SET_EMPLOYEE_INFO",
            JSON.stringify(res.data.user_info)
          );
        }
        if (JSON.stringify(this.$store.state.business) === "{}") {
          this.$store.dispatch(
            "SET_BUSINESS_INFO",
            JSON.stringify(res.data.business)
          );
        }
      });
    },
    autoCert() {
      this.axios.post("/exam/automatic_cert").then((res) => {
        console.log(res);
        if (res.data.code == 1) {
          return;
        } else {
          this.certInfo = res.data.info;
          setTimeout(() => {
            this.createCert();
          }, 800);
        }
      });
    },
    createCert() {
      html2canvas(this.$refs.certificate).then((canvas) => {
        let base64 = canvas.toDataURL("image/png");
        let fd = new FormData();
        fd.append("pic", base64);
        this.axios.post("/exam/cert_pic", fd).then((res) => {});
      });
    },
    // 登陆弹窗
    loginMask() {
      this.maskShow = true;
      this.loginShow = true;
    },
    closeLoginMask(formName) {
      this.maskShow = false;
      this.loginShow = false;

      this.$refs[formName].resetFields();
    },
    loginMethods(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let fd = new FormData();
          let { mobile, password } = this.loginForm;
          fd.append("mobile", mobile);
          fd.append("password", password);
          this.axios.post("/course/login/login_pwd", fd).then((res) => {
            if (res.data.code === 0) {
              this.$store.dispatch("SET_CLOUD_PC", res.data.data);
              this.$message.success(res.data.msg);
              this.$router.go(0);
              setTimeout(() => {
                this.maskShow = false;
                this.loginShow = false;
                this.$refs[formName].resetFields(); //清空表单
              }, 800);
            } else {
              this.$message.error(res.data.msg);
            }
          });
        }
      });
    },
    openMask() {
      this.maskShow = true;
      this.registerShow = true;
    },
    closeMask() {
      this.maskShow = false;
      this.registerShow = false;
      for (let i in this.resgisterForm) {
        this.resgisterForm[i] = "";
      }
    },
    sendCode() {
      if (this.resgisterForm.mobile === "") {
        this.$message.error("请输入手机号");
        return;
      }
      let fd = new FormData();
      fd.append("mobile", this.resgisterForm.mobile);
      this.axios.post("/course/login/sendcode", fd).then((res) => {
        if (res.data.code === 0) {
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
      });
      let timer = setInterval(() => {
        this.countDown--;
        if (this.countDown === 0) {
          clearInterval(timer);
          this.countDown = 60;
        }
      }, 1000);
    },
    openForget() {
      this.maskShow = true;
      this.forgetShow = true;
    },
    closeForget(formName) {
      this.maskShow = false;
      this.forgetShow = false;
      this.$refs[formName].resetFields();
    },
    registerReq() {
      let fd = new FormData();
      for (let item in this.resgisterForm) {
        if (this.resgisterForm[item] === "") {
          this.$message.error("请填写完整");
          return;
        } else {
          fd.append(item, this.resgisterForm[item]);
        }
      }
      this.axios.post("/course/login/register", fd).then((res) => {
        if (res.data.code === 0) {
          this.$message.success(res.data.msg);
          // setTimeout(() => {
          //   this.closeMask();
          //   this.$store.state.loginMethods();
          //   let fd = new FormData();
          //   let { mobile, password } = this.resgisterForm;
          //   fd.append("mobile", mobile);
          //   fd.append("password", password);
          //   this.axios.post("/login/login", fd).then((res) => {
          //     if (res.data.code === 0) {
          //       localStorage.setItem("eft", res.data.data.token);
          //       this.$message.success(res.data.msg);
          //       setTimeout(() => {
          //         this.maskShow = false;
          //         this.loginShow = false;
          //         this.$refs[loginForm].resetFields(); //清空表单
          //         this.bannerList = [];
          //         this.getIndexData();
          //       }, 800);
          //     } else {
          //       this.$message.error(res.data.msg);
          //     }
          //   });
          // }, 800);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 忘记密码
    forgetReq(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let fd = new FormData();
          fd.append("mobile", this.forgetForm.mobile);
          fd.append("code", this.forgetForm.code);
          fd.append("password", this.forgetForm.password);

          // DONE
          this.axios.post("/course/login/forget_pwd", fd).then((res) => {
            if (res.data.code === 0) {
              this.$message.success(res.data.msg);
              setTimeout(() => {
                this.closeForget(formName);
                this.$refs[formName].resetFields();
                // this.$store.state.loginMethods();
              }, 800);
            } else {
              this.$message.error(res.data.msg);
            }
          });
        }
      });
    },
    sendForgetCode() {
      if (this.forgetForm.mobile === "") {
        this.$message.error("请输入手机号");
        return;
      }
      let fd = new FormData();
      fd.append("mobile", this.forgetForm.mobile);
      this.axios.post("/course/login/sendcode", fd).then((res) => {
        if (res.data.code === 0) {
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
      });
      let timer = setInterval(() => {
        this.countDown--;
        if (this.countDown === 0) {
          clearInterval(timer);
          this.countDown = 60;
        }
      }, 1000);
    },
    // 注册验证
    checkMobile(b) {
      if (this.resgisterForm.mobile === "") {
        this.$refs[b].style.opacity = 1;
      } else {
        this.$refs[b].style.opacity = 0;
      }
    },
    checkCaptcha(b) {
      if (this.resgisterForm.captcha === "") {
        this.$refs[b].style.opacity = 1;
      } else {
        this.$refs[b].style.opacity = 0;
      }
    },
    checkPwd(b) {
      if (this.resgisterForm.password === "") {
        this.$refs[b].style.opacity = 1;
      } else {
        this.$refs[b].style.opacity = 0;
      }
    },
    checkRePwd(b) {
      if (this.resgisterForm.real_password === "") {
        this.$refs[b].style.opacity = 1;
      } else {
        this.$refs[b].style.opacity = 0;
      }
    },
  },
  computed: {
    userInfo() {
      if (JSON.stringify(this.$store.state.cloudPc) !== "{}") {
        console.log(1);
        return JSON.parse(this.$store.state.cloudPc);
      }
    },
  },
};
</script>
<style lang="scss">
:root {
  --animate-duration: 0.3s;
}
.search {
  .el-input,
  .search_in .el-input__inner {
    border-color: #dcdfe6 !important;
    border-radius: 0%;
    height: 40px;
  }
  .search_menu {
    .el-input .el-input__inner {
      border-color: #dcdfe6 !important;
      text-align: center;
      border-radius: 0;
      border-right: 0px;
    }
  }
}

@media screen and (max-width: 1378px) {
  .el-dropdown-menu {
    position: absolute;
    left: 1450px !important;
    top: 200px;
    // background: pink;
  }
}
@media screen and (max-width: 1555px) and (min-width: 1380px) {
  .el-dropdown-menu {
    position: absolute;
    left: 1530px !important;
    top: 200px;
  }
}

:root {
  --animate-duration: 0.3s;
}
.mask {
  .login-mask,
  .forget-mask {
    .el-input__inner {
      border-radius: 50px;
    }
    .el-button {
      border-radius: 50px;
      background-color: #8bb9fc;
      border: 0;
      color: #fff;
    }
  }
}
.register {
  .el-autocomplete {
    position: absolute;
    top: 13%;
    left: 10%;
    height: 110%;
    .el-input {
      height: 68%;
    }
    input {
      width: 120%;
      height: 100%;
      border: 0;
      outline: 0;
      font-size: 16px;
      padding: 0;
      padding-left: 5px;
      &::placeholder {
        color: rgba($color: #000000, $alpha: 0.3);
      }
    }
  }
}
</style>
<style lang="scss" scoped>
// header中去除eslint下划线
a {
  text-decoration: none;
}
.router-link-active {
  text-decoration: none;
}
.header {
  width: 100%;
  background: #ffffff;
  // padding: 0 40px;
  box-sizing: border-box;
  position: relative;
  .left {
    max-width: 1200px;
    height: 80px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo-wrapper {
      display: flex;
      align-items: center;
      .logo {
        height: 100%;
        img {
          width: 100%;
          max-height: 100%;
        }
      }
      span {
        margin-left: 10px;
        font-size: 20px;
      }
    }
    .center-menu {
      width: 15%;
      font-size: 17px;
      display: flex;
      justify-content: space-between;
      user-select: none;
      & > span {
        cursor: pointer;
      }
      .menu-index {
        &:hover {
          color: #409eff;
        }
      }
      .menu-types {
        position: relative;
        cursor: default;
        b {
          font-weight: normal;
          cursor: pointer;
          &:hover {
            color: #409eff;
          }
        }
        & > div {
          position: absolute;
          left: 50%;
          top: 30px;
          transform: translateX(-50%);
          width: 300px;
          height: 200px;
          background-color: #fff;
          z-index: 10;
          border-radius: 4px;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          // &::before {
          //   display: block;
          //   content: "";
          //   position: absolute;
          //   top: -12px;
          //   left: 50%;
          //   transform: translateX(-50%);
          //   border: 6px solid #fff;
          //   border-left-color: transparent;
          //   border-right-color: transparent;
          //   border-top-color: transparent;
          // }
        }
        .drop-down {
          min-width: 260px;
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          align-items: center;
          padding: 15px;
          box-sizing: border-box;
          font-size: 16px;
          div {
            max-width: 100px;
            height: calc(100% / 5);
            line-height: 33px;
            user-select: none;
            text-align: center;
            span {
              cursor: pointer;
              &:hover {
                color: #409eff;
              }
            }
          }
        }
      }
    }
    .search {
      width: 360px;
      display: flex;
      // flex-shrink: 0;
      .search_but {
        width: 130px;
        height: 40px;
        font-size: 16px;
        color: #ffffff;
        background: #8bb9ff;
        .search_but_text {
          // margin-top: 8px;
          // margin-left: 17px;
          margin: 0;
          line-height: 40px;
          text-align: center;
          cursor: pointer;
          font-size: 16px;
        }
      }
    }
    .right {
      height: 45px;
      position: absolute;
      right: 8%;
      color: #000;
      .please {
        margin-top: 5px;
        margin-left: 10px;
        cursor: pointer;
      }
      & > img {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        object-fit: cover;
      }
      .header_name {
        margin-left: 10px;
      }
      .el-icon--right {
        position: absolute;
        left: 50%;
      }
    }
  }
}
.mask {
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.39);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1111;
  .register {
    max-width: 680px;
    max-height: 647px;
    background: #ffffff;
    border: 1px solid #707070;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 1% 8%;
    box-sizing: border-box;
    span {
      display: inline-block;
      margin-top: 6%;
      color: #1890ff;
      font-size: 29px;
      position: relative;
      &::after {
        content: "";
        display: block;
        width: 46px;
        height: 3px;
        background: #1890ff;
        position: absolute;
        left: 50%;
        bottom: -5px;
        transform: translateX(-50%);
      }
    }
    i {
      position: absolute;
      right: 3%;
      font-size: 24px;
      cursor: pointer;
    }
    form {
      width: 350px;
      max-height: 500px;
      margin: 10% auto;
      label {
        display: block;
        width: 350px;
        height: 40px;
        background: #ffffff;
        border: 1px solid rgba(217, 217, 217, 0.65);
        border-radius: 20px;
        padding: 6px 5px;
        box-sizing: border-box;
        margin-bottom: 24px;
        background: url("../assets/images/sprite.png") no-repeat;
        position: relative;
        &::before {
          content: "*";
          color: red;
          position: absolute;
          top: 50%;
          left: -6%;
          transform: translateY(-50%);
        }
        input {
          width: 80%;
          height: 75%;
          border: 0;
          outline: 0;
          font-size: 16px;
          &::placeholder {
            color: rgba($color: #000000, $alpha: 0.3);
          }
        }

        b {
          font-weight: normal;
          color: #f56c6c;
          font-size: 12px;
          position: absolute;
          left: 0;
          bottom: -50%;
          opacity: 0;
          transition: all 0.5s;
        }
      }
      button.now-register {
        width: 239px;
        height: 40px;
        background: #8bb9fc;
        border-radius: 20px;
        font-size: 15px;
        color: #ffffff;
        border: 0;
        margin-top: 12px;
        cursor: pointer;
      }
      .label-name-icon {
        background-position: 4% -3%;
      }
      .label-unit-icon {
        background-position: 4% 18%;
      }
      .label-phone-icon {
        background-position: 4% 39%;
      }
      .label-code-icon {
        position: relative;
        background-position: 4% 61%;
        button.send-code-btn {
          height: 100%;
          border: 0;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          font-size: 12px;
          padding: 10px;
          box-sizing: border-box;
          border-radius: 50px;
          color: #000;
          cursor: pointer;
        }
        .send-disable {
          cursor: not-allowed !important;

          background-color: #e7e8e9;
        }
      }
      .label-password-icon {
        background-position: 4% 82%;
      }
    }
  }
  .login-mask {
    width: 25%;
    background: #ffffff;
    border: 1px solid #707070;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 20px 50px;
    box-sizing: border-box;
    span {
      display: inline-block;
      margin-top: 6%;
      color: #1890ff;
      font-size: 29px;
      position: relative;
      margin-bottom: 47px;
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        background: #1890ff;
        position: absolute;
        left: 50%;
        bottom: -5px;
        transform: translateX(-50%);
      }
    }
    i {
      position: absolute;
      right: 3%;
      font-size: 24px;
      cursor: pointer;
    }
  }
  .forget-mask {
    width: 25%;
    background: #ffffff;
    border: 1px solid #707070;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 20px 50px;
    box-sizing: border-box;
    span {
      display: inline-block;
      margin-top: 6%;
      color: #1890ff;
      font-size: 29px;
      position: relative;
      margin-bottom: 47px;
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        background: #1890ff;
        position: absolute;
        left: 50%;
        bottom: -5px;
        transform: translateX(-50%);
      }
    }
    i {
      position: absolute;
      right: 3%;
      font-size: 24px;
      cursor: pointer;
    }
    .msg-box {
      display: flex;
      button {
        width: 40%;
        border: 0;
        background-color: rgba($color: #8bb9fc, $alpha: 0.8);
        border-radius: 50px;
        cursor: pointer;
        color: #fff;

        &:hover {
          background-color: rgba($color: #8bb9fc, $alpha: 0.6);
        }
      }
    }
  }
}
</style>
